import React from "react";
import { Routes, Route } from "react-router-dom";

import AppWrapper from "./base/AppWrapper";

const About = React.lazy(() => import("./about/About"));
const Ask = React.lazy(() => import("./ask/Ask"));
const Contact = React.lazy(() => import("./contact/Contact"));
const DocumentLibrary = React.lazy(() => import("./document-library/DocumentLibrary"));
const EnterPayment = React.lazy(() => import("./EnterPayment"));
const ForgotPassword = React.lazy(() => import("./forgot-password/ForgotPassword"));
const Homepage = React.lazy(() => import("./homepage/Homepage"));
const Legal = React.lazy(() => import("./Legal"));
const LogOut = React.lazy(() => import("./LogOut"));
const Login = React.lazy(() => import("./Login"));
const MicrosoftLogin = React.lazy(() => import("./MicrosoftLogin"));
const MicrosoftRegister = React.lazy(() => import("./MicrosoftRegister"));
const PaymentSuccess = React.lazy(() => import("./PaymentSuccess"));
const Pricing = React.lazy(() => import("./pricing/Pricing"));
const Register = React.lazy(() => import("./Register"));
const Settings = React.lazy(() => import("./settings/Settings"));
const VerifyEmail = React.lazy(() => import("./VerifyEmail"));

export default () => {
  return <React.Fragment>
    <React.Suspense fallback={<React.Fragment />}>
      <Routes>
        <Route path="/about" Component={About} />
        <Route path="/ask" Component={AppWrapper(Ask)} />
        <Route path="/document-library" Component={AppWrapper(DocumentLibrary)} />
        <Route path="/contact" Component={Contact} />
        <Route path="/enter-payment" Component={EnterPayment} />
        <Route path="/forgot-password" Component={ForgotPassword} />
        <Route path="/" Component={Homepage} />
        <Route path="/legal" Component={Legal} />
        <Route path="/logout" Component={LogOut} />
        <Route path="/login" Component={Login} />
        <Route path="/microsoft-login" Component={MicrosoftLogin} />
        <Route path="/microsoft-register" Component={MicrosoftRegister} />
        <Route path="/payment-success" Component={PaymentSuccess} />
        <Route path="/pricing" Component={Pricing} />
        <Route path="/register" Component={Register} />
        <Route path="/settings" Component={AppWrapper(Settings)} />
        <Route path="/verify-email" Component={VerifyEmail} />
      </Routes>
    </React.Suspense>
  </React.Fragment>;
};
