import React from "react";
import plenumLogo from "../assets/logo.png";
import plenumTitle from "../assets/title.png";

export default React.memo(() => {
  return <React.Fragment>
    <div className="flex flex-row items-center gap-3">
      <img className="w-9 h-9" src={plenumLogo} alt="" />
      <img className="w-fit h-8" src={plenumTitle} alt="" />
    </div>
  </React.Fragment>;
});
