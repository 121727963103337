import React from "react";

type EffectCallback = () => any | (() => Promise<any>);

/**
 * Runs a callback on component mount only once
 */
export default (effect: EffectCallback): void => {
  const ran = React.useRef(false);

  React.useEffect(() => {
    if (ran.current) return;
    ran.current = true;

    effect();
  }, []);
}
