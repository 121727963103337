import { createAsyncThunk } from "@reduxjs/toolkit";

import ThunkResponse from "../../models/thunk-response";

import CreateFetchInit from "../../utils/create-fetch-init";
import env from "../../utils/env";

const qnaPost = env("REACT_APP_QNA_POST_ENDPOINT")!;

export const askQuestion = createAsyncThunk<ThunkResponse<any>, { question: string }>(
  "qna/post",
  async (payload) => {
    try {
      const response = await fetch(qnaPost, CreateFetchInit("POST", payload));

      const status = response.status;
      const data = response.body;

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk getQuestion", (e as any)?.message);
      return Promise.reject();
    }
  }
);
