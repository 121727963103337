import { createAsyncThunk } from "@reduxjs/toolkit";

import ThunkResponse from "../../models/thunk-response";
import Question from "../../models/question";

import CreateFetchInit from "../../utils/create-fetch-init";
import env from "../../utils/env";
import query from "../../utils/query";

const createQuestionEndpoint = env("REACT_APP_QUESTION_CREATE_ENDPOINT")!;
const deleteQuestionsEndpoint = env("REACT_APP_QUESTION_DELETE_ENDPOINT")!;
const getQuestionsEndpoint = env("REACT_APP_QUESTION_GET_ENDPOINT")!;

export const createQuestion = createAsyncThunk<ThunkResponse<Question.Create.Response>, Question.Create.Payload>(
  "question/create",
  async (payload) => {
    try {
      const response = await fetch(query(createQuestionEndpoint, ["token", payload.query.token]), CreateFetchInit("POST", payload.body));

      const status = response.status;
      let data: any;
      try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk createQuestion", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const deleteQuestions = createAsyncThunk<ThunkResponse<Question.Delete.Response>, Question.Delete.Payload>(
  "question/delete",
  async (payload) => {
    try {
      const response = await fetch(query(deleteQuestionsEndpoint, ["token", payload.token], ["user", payload.id]), CreateFetchInit("DELETE"));

      const status = response.status;
      let data: any;
      // try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk deleteQuestions", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const getUserLibrary = createAsyncThunk<ThunkResponse<Question.GetLibrary.Response>, Question.GetLibrary.Payload>(
  "question/get-library",
  async (payload) => {
    try {
      const response = await fetch(query(getQuestionsEndpoint, ["token", payload.token], ["user", payload.user]), CreateFetchInit("GET"));

      const status = response.status;
      let data: any;
      try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk getLibrary", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const getQuestion = createAsyncThunk<ThunkResponse<Question.Get.Response>, Question.Get.Payload>(
  "question/get",
  async (payload) => {
    try {
      const response = await fetch(query(getQuestionsEndpoint, ["token", payload.token], ["id", payload.id]), CreateFetchInit("GET"));

      const status = response.status;
      let data: any;
      try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk getQuestion", (e as any)?.message);
      return Promise.reject();
    }
  }
);
