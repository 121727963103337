import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import configuration from "./configuration";
import store, { persistor } from "./app/store";
import env from "./utils/env";
import "./index.css";

import App from "./App";

const instance = new PublicClientApplication(configuration);

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GoogleOAuthProvider clientId={env("REACT_APP_GOOGLE_CLIENT_ID")!}>
            <MsalProvider instance={instance}>
              <App />
            </MsalProvider>
          </GoogleOAuthProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
