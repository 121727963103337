import { Configuration } from "@azure/msal-browser";
import env from "./utils/env";

const configuration: Configuration = {
  auth: {
    clientId: env("REACT_APP_MICROSOFT_IDENTITY_CLIENT_ID")!,
    navigateToLoginRequestUrl: false
  }
};

export default configuration;
