import React from "react";
import useAppSelector from "../../hooks/use-app-selector";
import { Link } from "react-router-dom";

export default () => {
  const { loggedInUser } = useAppSelector(store => store.user);

  const initials = !loggedInUser ? "" : `${loggedInUser.first_name[0]}${loggedInUser.last_name[0]}`;

  return <React.Fragment>
    <Link to="/settings">
      <div className="flex items-center justify-center rounded-full bg-white lg:bg-black w-12 h-12">
        <p className="text-black lg:text-white text-base font-bold">{initials}</p>
      </div>
    </Link>
  </React.Fragment>;
};
