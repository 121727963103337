import React from "react";
import useAppDispatch from "../../hooks/use-app-dispatch";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { clearAnswer } from "../../app/slices/question-slice";
import askLink from "../../assets/ask-link.svg";
import documentLibraryLink from "../../assets/document-library-link.svg";
import settingsLink from "../../assets/settings-link.svg";
import cn from "classnames";

export default () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const ask = pathname === "/ask";
  const documentLibrary = pathname.startsWith("/document-library");
  const settings = pathname === "/settings";

  const handleAskClick = () => {
    if (pathname === "/ask") dispatch(clearAnswer());
    else navigate("/ask");
  };

  return <React.Fragment>
    <div className="hidden lg:flex flex-row items-center px-6 gap-8">
      <div className={cn("flex flex-row items-center gap-2 py-3 border-b-[3px] cursor-pointer",
        { "border-lite-blue": ask }, { "border-transparent": !ask })} onClick={handleAskClick}>
        <img src={askLink} alt="" />

        <p className={cn("text-lite-grey font-bold", { "text-lite-blue": ask })}>ASK</p>
      </div>

      <Link to="/document-library">
        <div className={cn("flex flex-row items-center gap-2 py-3 border-b-[3px]",
          { "border-lite-blue": documentLibrary }, { "border-transparent": !documentLibrary })}>
          <img src={documentLibraryLink} alt="" />

          <p className={cn("text-lite-grey font-bold", { "text-lite-blue": documentLibrary })}>Document Library</p>
        </div>
      </Link>

      <Link to="/settings">
        <div className={cn("flex flex-row items-center gap-2 py-3 border-b-[3px]",
          { "border-lite-blue": settings }, { "border-transparent": !settings })}>
          <img src={settingsLink} alt="" />

          <p className={cn("text-lite-grey font-bold", { "text-lite-blue": settings })}>Settings</p>
        </div>
      </Link>
    </div>
  </React.Fragment>;
};
