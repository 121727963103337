import React from "react";
import { Link } from "react-router-dom";
import Question from "../models/question";
import moment from "moment";

interface Props {
  question: Question.Flat;
}

export default React.memo<Props>((props) => {
  const question = props.question.question.slice(0, 40);
  const date = moment(props.question.created).format("MM/DD/YYYY");
  const time = moment(props.question.created).format("hh:mm A");

  return <React.Fragment>
    <div className="flex flex-col justify-between bg-[#00000033] rounded border border-border-grey-dark-bg p-3 w-[270px] max-h-[90px] h-[90px] gap-3">
      <p className="text-white text-xs max-w-[234px] break-words">{question}</p>

      <div className="flex flex-row items-center justify-between select-none">
        <p className="text-[#C7CFDF] text-xs">{date}</p>
        <p className="text-[#C7CFDF] text-xs">{time}</p>

        <Link to={`/document-library?question=${props.question.id}`}>
          <p className="text-lite-blue text-sm font-normal underline cursor-pointer">Link to Document</p>
        </Link>
      </div>
    </div>
  </React.Fragment>;
});
