import React from "react";
import useAppDispatch from "../../hooks/use-app-dispatch";
import { clearAnswer } from "../../app/slices/question-slice";
import { Link, useLocation, useNavigate } from "react-router-dom";

import closeDrawer from "../../assets/close-drawer.svg";
import askLink from "../../assets/ask-link.svg";
import documentLibraryLink from "../../assets/document-library-link.svg";
import settingsLink from "../../assets/settings-link.svg";

interface Props {
  onClose: () => void;
}

export default (props: Props) => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleAskClick = () => {
    props.onClose();

    if (pathname === "/ask") dispatch(clearAnswer());
    else navigate("/ask");
  };

  return <React.Fragment>
    <div className="flex flex-col">
      <div className="flex flex-row justify-end cursor-pointer">
        <img className="p-3" onClick={props.onClose} src={closeDrawer} alt="" />
      </div>

      <div className="flex flex-col gap-3">
        <div className="flex flex-row items-center px-3 gap-3" onClick={handleAskClick}>
          <img src={askLink} alt="" />

          <p>ASK</p>
        </div>

        <Link to="/document-library" onClick={props.onClose}>
          <div className="flex flex-row items-center px-3 gap-3">
            <img src={documentLibraryLink} alt="" />

            <p>Document Library</p>
          </div>
        </Link>

        <Link to="/settings" onClick={props.onClose}>
          <div className="flex flex-row items-center px-3 gap-3">
            <img src={settingsLink} alt="" />

            <p>Settings</p>
          </div>
        </Link>
      </div>
    </div>
  </React.Fragment>;
};
