import React from "react";
import "react-modern-drawer/dist/index.css";

import Ellipses from "./Ellipses";
import PlenumLogoTitle from "../PlenumLogoTitle";
import Links from "./Links";
import InitialsAvatar from "./InitialsAvatar";
import Hamburger from "./Hamburger";

export default () => {
  return <React.Fragment>
    <div className="flex flex-row items-center justify-between bg-darkblue lg:bg-transparent min-w-full w-full h-fit px-6 sm:px-8 select-none">
      <div> {/* this forces justify-between for large breakpoints */}
        <Ellipses />
      </div>

      <div className="lg:hidden">
        <PlenumLogoTitle />
      </div>

      <div className="flex flex-row items-center gap-1 sm:gap-4 py-5">
        <Links />

        <InitialsAvatar />

        <Hamburger />
      </div>
    </div>
  </React.Fragment>;
};
