import React from "react";
import { Circles } from "react-loader-spinner";

export default () => {
  return <React.Fragment>
    <div className="flex items-center justify-center h-screen bg-darkblue">
      <Circles color="#5A83EC" />
    </div>
  </React.Fragment>;
};
