import React from "react";
import Drawer from "react-modern-drawer";
import ellipses from "../../assets/ellipses.svg";
import QuestionSidebar from "../QuestionSidebar";

export default () => {
  const [sidebarDrawer, setSidebarDrawer] = React.useState(false);

  const handleToggleSidebarDrawer = () => setSidebarDrawer(prev => !prev);

  return <React.Fragment>
    <Drawer open={sidebarDrawer} onClose={handleToggleSidebarDrawer} direction="left">
      <QuestionSidebar />
    </Drawer>

    <img className="cursor-pointer lg:hidden"
      onClick={handleToggleSidebarDrawer} src={ellipses} alt="" />
  </React.Fragment>;
};
