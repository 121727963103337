import React from "react";
import useAppDispatch from "../hooks/use-app-dispatch";
import useAppSelector from "../hooks/use-app-selector";
import useRefreshToken from "../hooks/use-refresh-token";
import { getUserLibrary } from "../app/api/question-api";
import { TailSpin } from "react-loader-spinner";
import useLoggedInUser from "../hooks/use-logged-in-user";
import PlenumLogoTitle from "./PlenumLogoTitle";
import QuestionCard from "./QuestionCard";
import useMount from "../hooks/use-mount";

export default () => {
  const dispatch = useAppDispatch();
  const { previousQuestions, fetchingLibrary, lastLibraryFetch } = useAppSelector(store => store.question);
  const getUserLibraryRefresher = useRefreshToken(dispatch, getUserLibrary);
  const loggedInUser = useLoggedInUser();

  useMount(() => {
    if (!!loggedInUser?.id && Date.now() > lastLibraryFetch) {
      getUserLibraryRefresher(token => ({ token, user: loggedInUser.id }));
    }
  });

  return <React.Fragment>
    <div className="flex flex-col items-center bg-darkblue min-w-fit h-screen px-6 pt-8 gap-10 overflow-x-hidden">
      <div className="hidden lg:block select-none">
        <PlenumLogoTitle />
      </div>

      <div className="flex flex-col gap-3 w-full">
        <div className="flex flex-row items-center gap-1.5">
          <p className="text-lite-grey text-sm font-medium select-none">Recent</p>
          {fetchingLibrary && <TailSpin width={15} height={15} color="#717D96" />}
        </div>

        {previousQuestions?.map((q, i) => <React.Fragment key={i}>
          <QuestionCard question={q} />
        </React.Fragment>)}
      </div>

      {/* force static width */}
      <div className="w-[270px]" />
    </div>
  </React.Fragment>;
};
