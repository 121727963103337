import React from "react";

import QuestionSidebar from "../QuestionSidebar";
import Header from "../header/Header";

export default (Component: React.FC) => () => {
  return <React.Fragment>
    <div className="flex flex-row items-center w-screen h-screen max-w-screen max-h-screen overflow-hidden">
      <div className="hidden lg:block">
        <QuestionSidebar />
      </div>

      <div className="flex flex-col w-full h-screen max-h-screen overflow-hidden">
        <Header />

        <Component />
      </div>
    </div>
  </React.Fragment>;
};
