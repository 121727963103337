import React from "react";
import Router from "./components/Router";
import FullPageLoader from "./components/FullPageLoader";
import useRedirect from "./hooks/use-redirect";

export default () => {
  const { loading } = useRedirect();

  if (loading) return <FullPageLoader />;

  return <React.Fragment>
    <Router />
  </React.Fragment>;
};
