import { createSlice } from "@reduxjs/toolkit";

import { loginGoogleUser, loginMicrosoftUser, loginUser } from "../api/user-api";
import User from "../../models/user";

interface InitialState {
  loggedInUser: undefined | User;
}

const initialState: InitialState = {
  loggedInUser: undefined
}

export const userSlice = createSlice({
  name: "user", initialState,
  reducers: {
    clearUser: () => initialState
  },
  extraReducers: builder => builder
    .addCase(loginGoogleUser.rejected, (state) => {
      state.loggedInUser = undefined;
    })
    .addCase(loginGoogleUser.fulfilled, (state, { payload }) => {
      if (payload.status !== 200) return state.loggedInUser = undefined;
      state.loggedInUser = payload.data;
    })

    .addCase(loginMicrosoftUser.rejected, (state) => {
      state.loggedInUser = undefined;
    })
    .addCase(loginMicrosoftUser.fulfilled, (state, { payload }) => {
      if (payload.status !== 200) return state.loggedInUser = undefined;
      state.loggedInUser = payload.data;
    })

    .addCase(loginUser.rejected, (state) => {
      state.loggedInUser = undefined;
    })
    .addCase(loginUser.fulfilled, (state, { payload }) => {
      if (payload.status !== 200) return state.loggedInUser = undefined;
      state.loggedInUser = payload.data;
    })
})

export const { clearUser } = userSlice.actions;
export default userSlice.reducer;
