import { createSlice } from "@reduxjs/toolkit";

import Question from "../../models/question";
import { getUserLibrary, getQuestion } from "../api/question-api";
import { askQuestion } from "../api/qna-api";

interface InitialState {
  previousQuestions: undefined | Question[];
  currentQuestion: undefined | Question;
  answer: undefined | Question.Answer;
  library: undefined | Question.GetLibrary.Response;
  lastLibraryFetch: number;
  askStatus: "idle" | "pending" | "fulfilled" | "rejected";
  questionAsking: undefined | string;
  fetchingQuestion: boolean;
  fetchingLibrary: boolean;
}

const initialState: InitialState = {
  previousQuestions: undefined,
  currentQuestion: undefined,
  answer: undefined,
  library: undefined,
  lastLibraryFetch: 0,
  askStatus: "idle",
  questionAsking: undefined,
  fetchingQuestion: false,
  fetchingLibrary: false
}

export const questionSlice = createSlice({
  name: "question", initialState,
  reducers: {
    appendAnswerText: (state, action) => {
      state.answer!.answer.summary += action.payload;
    },
    addAnswerSources: (state, action) => {
      state.answer!.answer = action.payload;
    },
    clearQuestion: () => initialState,
    clearAnswer: (state) => {
      state.askStatus = "idle";
      state.answer = undefined;
    },
    createEmptyAnswer: (state, action) => {
      state.askStatus = "fulfilled";
      state.lastLibraryFetch = 0;
      state.answer = {
        question: action.payload,
        answer: {
          previews: [],
          sources: [],
          summary: "",
        }
      };
    },
    clearQuestionAsking: (state) => {
      state.questionAsking = undefined;
    },
    openError: (state) => {
      state.askStatus = "rejected";
      state.questionAsking = undefined;
    }
  },
  extraReducers: builder => builder
    .addCase(askQuestion.pending, (state, action) => {
      state.askStatus = "pending";
      state.questionAsking = action.meta.arg.question;
    })
    .addCase(askQuestion.rejected, (state) => {
      state.askStatus = "rejected";
      state.questionAsking = undefined;
    })

    .addCase(getQuestion.pending, (state) => {
      state.fetchingQuestion = true;
      state.currentQuestion = undefined;
    })
    .addCase(getQuestion.fulfilled, (state, action) => {
      state.fetchingQuestion = false;
      state.currentQuestion = action.payload.data;
    })
    .addCase(getQuestion.rejected, (state) => {
      state.fetchingQuestion = false;
    })

    .addCase(getUserLibrary.pending, (state) => {
      state.fetchingLibrary = true;
    })
    .addCase(getUserLibrary.fulfilled, (state, action) => {
      if (action.payload.status !== 200) return;
      state.fetchingLibrary = false;
      state.library = action.payload.data;
      state.lastLibraryFetch = Date.now() + 60_000;
      state.previousQuestions = action.payload.data.slice(0, 8);
    })
    .addCase(getUserLibrary.rejected, (state) => {
      state.fetchingLibrary = false;
    })
})

export const { appendAnswerText, addAnswerSources, clearQuestion, clearAnswer, createEmptyAnswer, clearQuestionAsking, openError } = questionSlice.actions;
export default questionSlice.reducer;
