import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLoggedInUser from "./use-logged-in-user";
import useAppDispatch from "./use-app-dispatch";
import loginEnvUser from "../utils/login-env-user";
import getRedirect from "../utils/get-redirect";
import timeout from "../utils/timeout";
import useMount from "./use-mount";

/**
 * Redirects according to a user's authentication status
 * 
 * This only runs when the app first loads, and when the user manually navigates to a new path
 * 
 * This doesn't affect app routing, and each component should navigate the app with logic to prevent users from accessing bad routes
 * 
 * @returns a loading status
 */
export default (): { loading: boolean } => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loggedInUser = useLoggedInUser();
  const { pathname } = useLocation();
  const [loading, setLoading] = React.useState(true);

  const handleNavigate = (route: undefined | string) => {
    if (Boolean(route)) navigate(route!, { replace: true });
    setLoading(false);
  };

  useMount(async () => {
    try {
      const logout = pathname === "/logout";
      const microsoft = (pathname === "/microsoft-register") || (pathname === "/microsoft-login");

      if (logout || microsoft) return setLoading(false);

      if (!loggedInUser?.id) return handleNavigate(getRedirect(undefined, pathname));

      await timeout(500);

      const { status, data } = await loginEnvUser(dispatch, loggedInUser, { body: loggedInUser });

      if (status !== 200) return handleNavigate(getRedirect(undefined, pathname));

      handleNavigate(getRedirect(data, pathname));
    } catch (e) {
      console.error(e);
      handleNavigate("/login");
    }
  });

  return { loading };
};
