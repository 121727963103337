import { createAsyncThunk } from "@reduxjs/toolkit";

import ThunkResponse from "../../models/thunk-response";
import User from "../../models/user";

import CreateFetchInit from "../../utils/create-fetch-init";
import env from "../../utils/env";
import query from "../../utils/query";

const changePasswordEndpoint = env("REACT_APP_USER_CHANGE_PASSWORD_ENDPOINT")!;
const deleteUserEndpoint = env("REACT_APP_USER_DELETE_ENDPOINT")!;
const forgotPasswordEndpoint = env("REACT_APP_USER_FORGOT_PASSWORD_ENDPOINT")!;
const loginGoogleUserEndpoint = env("REACT_APP_USER_GOOGLE_LOGIN_ENDPOINT")!;
const loginMicrosoftUserEndpoint = env("REACT_APP_USER_MICROSOFT_LOGIN_ENDPOINT")!;
const loginUserEndpoint = env("REACT_APP_USER_LOGIN_ENDPOINT")!;
const registerGoogleUserEndpoint = env("REACT_APP_USER_GOOGLE_REGISTER_ENDPOINT")!;
const registerMicrosoftUserEndpoint = env("REACT_APP_USER_MICROSOFT_REGISTER_ENDPOINT")!;
const registerUserEndpoint = env("REACT_APP_USER_REGISTER_ENDPOINT")!;
const updateGoogleUserEndpoint = env("REACT_APP_USER_GOOGLE_UPDATE_ENDPOINT")!;
const updateMicrosoftUserEndpoint = env("REACT_APP_USER_MICROSOFT_UPDATE_ENDPOINT")!;
const updateUserEndpoint = env("REACT_APP_USER_UPDATE_ENDPOINT")!;

export const changePassword = createAsyncThunk<ThunkResponse<User.ChangePassword.Response>, User.ChangePassword.Payload>(
  "user/change-password",
  async (payload) => {
    try {
      const response = await fetch(query(changePasswordEndpoint, ["token", payload.query!.token!]), CreateFetchInit("PUT", payload.body));

      const status = response.status;
      let data: any;
      // try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk changePassword", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const deleteUser = createAsyncThunk<ThunkResponse<User.Delete.Response>, User.Delete.Payload>(
  "user/delete",
  async (payload) => {
    try {
      const response = await fetch(query(deleteUserEndpoint, ["token", payload.query!.token], ["id", payload.query!.id]), CreateFetchInit("DELETE"));

      const status = response.status;
      let data: any;
      // try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk deleteUser", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const forgotPassword = createAsyncThunk<ThunkResponse<User.ForgotPassword.Response>, User.ForgotPassword.Payload>(
  "user/forgot-password",
  async (payload) => {
    try {
      const response = await fetch(query(forgotPasswordEndpoint, ["email", payload.query!.email]), CreateFetchInit("POST"));

      const status = response.status;
      let data: any;
      try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk forgotPassword", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const loginGoogleUser = createAsyncThunk<ThunkResponse<User.GoogleLogin.Response>, User.GoogleLogin.Payload>(
  "user/login-google",
  async (payload) => {
    try {
      const response = await fetch(loginGoogleUserEndpoint, CreateFetchInit("POST", payload.body));

      const status = response.status;
      let data: any;
      try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk loginGoogleUser", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const loginMicrosoftUser = createAsyncThunk<ThunkResponse<User.MicrosoftLogin.Response>, User.MicrosoftLogin.Payload>(
  "user/login-microsoft",
  async (payload) => {
    try {
      const response = await fetch(loginMicrosoftUserEndpoint, CreateFetchInit("POST", payload.body));

      const status = response.status;
      let data: any;
      try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk loginMicrosoftUser", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const loginUser = createAsyncThunk<ThunkResponse<User.Login.Response>, User.Login.Payload>(
  "user/login",
  async (payload) => {
    try {
      const response = await fetch(loginUserEndpoint, CreateFetchInit("POST", payload.body));

      const status = response.status;
      let data: any;
      try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk loginUser", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const registerGoogleUser = createAsyncThunk<ThunkResponse<User.GoogleRegister.Response>, User.GoogleRegister.Payload>(
  "user/register-google",
  async (payload) => {
    try {
      const response = await fetch(registerGoogleUserEndpoint, CreateFetchInit("POST", payload.body));

      const status = response.status;
      let data: any;
      // try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk registerGoogleUser", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const registerMicrosoftUser = createAsyncThunk<ThunkResponse<User.MicrosoftRegister.Response>, User.MicrosoftRegister.Payload>(
  "user/register-microsoft",
  async (payload) => {
    try {
      const response = await fetch(registerMicrosoftUserEndpoint, CreateFetchInit("POST", payload.body));

      const status = response.status;
      let data: any;
      // try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk registerMicrosoftUser", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const registerUser = createAsyncThunk<ThunkResponse<User.Register.Response>, User.Register.Payload>(
  "user/register",
  async (payload) => {
    try {
      const response = await fetch(registerUserEndpoint, CreateFetchInit("POST", payload.body));

      const status = response.status;
      let data: any;
      // try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk registerUser", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const updateGoogleUser = createAsyncThunk<ThunkResponse<User.GoogleUpdate.Response>, User.GoogleUpdate.Payload>(
  "user/update-google",
  async (payload) => {
    try {
      const response = await fetch(query(updateGoogleUserEndpoint, ["token", payload.query!.token!]), CreateFetchInit("PUT", payload.body));

      const status = response.status;
      let data: any;
      // try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk updateGoogleUser", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const updateMicrosoftUser = createAsyncThunk<ThunkResponse<User.MicrosoftUpdate.Response>, User.MicrosoftUpdate.Payload>(
  "user/update-microsoft",
  async (payload) => {
    try {
      const response = await fetch(query(updateMicrosoftUserEndpoint, ["token", payload.query!.token!]), CreateFetchInit("PUT", payload.body));

      const status = response.status;
      let data: any;
      // try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk updateMicrosoftUser", (e as any)?.message);
      return Promise.reject();
    }
  }
);

export const updateUser = createAsyncThunk<ThunkResponse<User.Update.Response>, User.Update.Payload>(
  "user/update",
  async (payload) => {
    try {
      const response = await fetch(query(updateUserEndpoint, ["token", payload.query!.token!]), CreateFetchInit("PUT", payload.body));

      const status = response.status;
      let data: any;
      // try { data = await response.json(); } catch { }

      return { status, data };
    } catch (e) {
      console.error("Exception thrown in thunk updateUser", (e as any)?.message);
      return Promise.reject();
    }
  }
);
