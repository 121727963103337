import { AsyncThunk, ThunkDispatch } from "@reduxjs/toolkit";
import useLoggedInUser from "./use-logged-in-user";
import loginEnvUser from "../utils/login-env-user";
import getResponse from "../utils/get-response";
import ThunkResponse from "../models/thunk-response";

/**
 * This hook is for automatically refreshing the user's token when expired
 * 
 * It will return a function you can call with a lambda argument to build a payload with the user token
 * 
 * This function returns a `ThunkResponse`
 * 
 * After a refresh token is aquired, it will only run the thunk one more time
 */
export default (dispatch: ThunkDispatch<any, any, any>, thunk: AsyncThunk<any, any, any>) => {
  const loggedInUser = useLoggedInUser();

  /**
   * This function will handle the token refresh and return the thunk response
   * 
   * @param createPayload A lambda that has the token and returns the payload
   */
  return async <T = any>(createPayload?: (token: string) => any): Promise<ThunkResponse<T>> => {
    const res = await getResponse(dispatch(thunk(createPayload?.(loggedInUser!.token))));

    if (res.status !== 401) return res;

    const { data } = await loginEnvUser(dispatch, loggedInUser, { body: loggedInUser! });

    return await getResponse(dispatch(thunk(createPayload?.(data!.token))));
  };
}
