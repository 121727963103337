import { loginGoogleUser, loginMicrosoftUser, loginUser } from "../app/api/user-api";
import { AppDispatch } from "../app/store";
import ThunkResponse from "../models/thunk-response";
import getResponse from "./get-response";
import User from "../models/user";

export default async (dispatch: AppDispatch, loggedInUser: undefined | User, login: User.Login.Payload): Promise<ThunkResponse<User.Login.Response | User.MicrosoftLogin.Response>> => {
  if (Boolean(loggedInUser?.google_id)) {
    const payload: User.GoogleLogin.Payload = {
      body: {
        google_id: loggedInUser?.google_id!
      }
    };

    return await getResponse(dispatch(loginGoogleUser(payload)));
  }

  if (Boolean(loggedInUser?.microsoft_unique_id)) {
    const payload: User.MicrosoftLogin.Payload = {
      body: {
        microsoft_unique_id: loggedInUser?.microsoft_unique_id!
      }
    };

    return await getResponse(dispatch(loginMicrosoftUser(payload)));
  }

  const payload = login;

  return await getResponse(dispatch(loginUser(payload)));
};
