import React from "react";
import hamburger from "../../assets/hamburger.svg";
import Drawer from "react-modern-drawer";

import HeaderLinksDrawer from "./LinksDrawer";

export default () => {
  const [linksDrawer, setLinksDrawer] = React.useState(false);

  const handleToggleLinksDrawer = () => setLinksDrawer(prev => !prev);

  return <React.Fragment>
    <Drawer open={linksDrawer} onClose={handleToggleLinksDrawer} direction="right">
      <HeaderLinksDrawer onClose={handleToggleLinksDrawer} />
    </Drawer>

    <img className="cursor-pointer lg:hidden"
      onClick={handleToggleLinksDrawer} src={hamburger} alt="" />
  </React.Fragment>;
};
