import User from "../models/user";

const loggedOutRoutes = ["/", "/pricing", "/about", "/contact", "/legal", "/login", "/forgot-password", "/register"];
const loggedInRoutes = ["/ask", "/document-library", "/settings"];

/**
 * If a user is not logged in and are not at a logged out route, send them to `/`
 * 
 * If a user is logged in and is not email verified, send them to "/verify-email"
 * 
 * If a user is logged in and is not paying, send them to "enter-payment"
 * 
 * If a user is logged in an is not at an app route, send them to "/ask"
 */
export default (loggedInUser: undefined | User, pathname: string): undefined | string => {
  const isLoggedOutRoute = loggedOutRoutes.includes(pathname);
  // if (!loggedInUser && !isLoggedOutRoute) return "/";
  if (!loggedInUser) return !isLoggedOutRoute ? "/" : undefined;

  if (!loggedInUser.email_verified) return "/verify-email";
  if (!loggedInUser.paying) return "/enter-payment";

  const isLoggedInRoute = loggedInRoutes.includes(pathname);

  return !isLoggedInRoute ? "ask" : undefined;
};
