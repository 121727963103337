import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import user from "./slices/user-slice";
import question from "./slices/question-slice";

const persistConfig = { key: "root", storage };

const persistedUserSlice = persistReducer(persistConfig, user);

const store = configureStore({
  reducer: {
    user: persistedUserSlice, question
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);
export default store;
