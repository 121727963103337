type Methods = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

/**
 * @returns request init object for fetch
 * 
 * @example
 * import CreateFetchInit from ".";
 * 
 * const payload = {
 *  email: "", password: ""
 * };
 * 
 * const response = await fetch(
 *  "/api/users/login",
 *  CreateFetchInit("POST", payload)
 * );
 */
export default (method: Methods, body?: any): RequestInit => ({
  method,
  headers: {
    "Content-Type": "application/json"
  },
  body: !body ? undefined : JSON.stringify(body)
});
