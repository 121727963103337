import { PayloadAction } from "@reduxjs/toolkit";
import ThunkResponse from "../models/thunk-response";

type Dispatch<T> = Promise<PayloadAction<ThunkResponse<T>, any, any, any> | PayloadAction<any>>;

export default async <T = any>(dispatch: Dispatch<T>): Promise<ThunkResponse<T>> => {
  const response = await dispatch;
  return {
    status: response.payload.status,
    data: response.payload.data
  };
};
